<template>
  <!-- eslint-disable -->
  <article>
    <header>
      <h1 class="u-h1">Datenschutzerklärung</h1>
      <p class="u-p">Diese Website (nachfolgend „Website“) wird bereitgestellt von Bayer Vital GmbH (nachfolgend „uns“ oder „wir“). Weitere Informationen zum Anbieter der Website finden Sie in unserem <router-link to="/impressum">Impressum</router-link>.</p>
    </header>
    <section>
      <ul>
        <li><anchor-link to="umgang-mit-personenbezogenen-daten">Umgang mit personenbezogenen Daten</anchor-link></li>
        <li><anchor-link to="nutzung-der-website">Nutzung der Website</anchor-link></li>
        <li><anchor-link to="aufruf-der-website">Aufruf der Website</anchor-link></li>
        <li><anchor-link to="einsatz-von-cookies">Einsatz von Cookies</anchor-link></li>
        <li><anchor-link to="ihre-einwilligung-vorausgesetzt">Ihre Einwilligung vorausgesetzt</anchor-link></li>
        <li><anchor-link to="website-analyse-mit-google">Website-Analyse mit Google</anchor-link></li>
        <li><anchor-link to="verhaltensbasierte-werbung-mit-google">Verhaltensbasierte Werbung mit Google</anchor-link></li>
        <li><anchor-link to="verhaltensbasierte-werbung-mit-facebook">Verhaltensbasierte Werbung mit Facebook</anchor-link></li>
        <li><anchor-link to="online-verhaltenswerbung">Online-Verhaltenswerbung</anchor-link></li>
        <li><anchor-link to="nutzung-von-google-recaptcha">Nutzung von Google reCaptcha</anchor-link></li>
        <li><anchor-link to="nutzung-von-spotify">Nutzung von Spotify</anchor-link></li>
        <li><anchor-link to="nutzung-facebook-pixel">Nutzung Facebook Pixel</anchor-link></li>
        <li><anchor-link to="nutzung-von-kontaktformularen">Nutzung von Kontaktformularen</anchor-link></li>
        <li><anchor-link to="externe-dienste-und-inhalte-auf-unserer-website">Externe Dienste und Inhalte auf unserer Website</anchor-link></li>
        <li><anchor-link to="hinweise-zu-nebenwirkungen-oder-qualitätsbeanstandungen">Hinweise zu Nebenwirkungen oder Qualitätsbeanstandungen</anchor-link></li>
        <li><anchor-link to="weitergabe-von-daten-zur-verarbeitung-in-unserem-auftrag">Weitergabe von Daten zur Verarbeitung in unserem Auftrag</anchor-link></li>
        <li><anchor-link to="verarbeitung-von-daten-außerhalb-der-eu-des-ewr">Verarbeitung von Daten außerhalb der EU / des EWR</anchor-link></li>
        <li><anchor-link to="emotionserkennung-gewinnspiel">Emotionserkennung & Gewinnspiel</anchor-link></li>
        <li><anchor-link to="informationen-über-ihre-rechte">Informationen über Ihre Rechte</anchor-link></li>
        <li><anchor-link to="kontakt">Kontakt</anchor-link></li>
        <li><anchor-link to="anpassung-der-datenschutzerklärung">Anpassung der Datenschutzerklärung</anchor-link></li>
      </ul>
    </section>
    <section>
      <a name="umgang-mit-personenbezogenen-daten"></a>
      <h2 class="u-h1">Umgang mit personenbezogenen Daten</h2>
      <p class="u-p">Im Folgenden möchten wir Sie über unseren Umgang mit personenbezogenen Daten bei der Nutzung dieser Website informieren. Soweit in den folgenden Abschnitten nicht anders beschrieben, folgt die Rechtsgrundlage für einen Umgang mit Ihren personenbezogenen Daten aus der Erforderlichkeit eines Umgangs für die Bereitstellung der auf dieser Website von Ihnen angefragten Funktionalitäten (Art. 6(1)(b) Datenschutz-Grundverordnung).</p>
    </section>
    <section>
      <a name="nutzung-der-website"></a>
      <h3>Nutzung der Website</h3>
      <a name="aufruf-der-website"></a>
      <h4>Aufruf der Website</h4>
      <p class="u-p">Wenn Sie unsere Website aufrufen, überträgt Ihr Browser technisch bedingt bestimmte Daten an unseren Webserver, um Ihnen die von Ihnen aufgerufenen Informationen zur Verfügung zu stellen. Um Ihnen den Besuch der Website zu ermöglichen, werden dabei folgende Daten erhoben, kurzfristig gespeichert und verwendet:</p>
      <ul>
        <li>IP-Adresse</li>
        <li>Datum und Uhrzeit der Anfrage</li>
        <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
        <li>Inhalt der Anforderung (konkrete Seite)</li>
        <li>Zugriffsstatus / HTTP-Statuscode</li>
        <li>Übertragene Datenmenge</li>
        <li>Website, von der die Anforderung kommt</li>
        <li>Browser, Sprache und Version der Browsersoftware</li>
      </ul>
      <p class="u-p">Darüber hinaus speichern wir zur Wahrung unserer berechtigten Interessen diese Daten für eine begrenzte Zeit, um im Falle unzulässiger Zugriffe bzw. Zugriffsversuche auf hiesige Server, eine Herleitung zu personenbezogenen Daten zu veranlassen (Art. 6(1)(f) Datenschutz-Grundverordnung).</p>
    </section>
    <section>
      <a name="einsatz-von-cookies"></a>
      <h4>Einsatz von Cookies</h4>
      <h5>Was Cookies sind</h5>
      <p class="u-p">Auf dieser Website setzen wir so genannte „Cookies“ ein. Cookies sind kleine Textdateien, die über Ihren Browser  im Speicher Ihres Endgeräts abgelegt werden. Cookies speichern bestimmte Informationen (z.B. die von Ihnen bevorzugte Sprache oder Seiteneinstellungen), die durch Ihren Browser bei einem erneuten Besuch der Website an uns zurückgesendet werden können (abhängig von der Lebensdauer des Cookies).</p>
      <h5>Welche Cookies wir einsetzen</h5>
      <p class="u-p">Wir unterscheiden zwei Kategorien von Cookies: (1) <strong>funktionsbezogene Cookies</strong>, ohne die die Funktionalität unserer Website eingeschränkt wäre und (2) <strong>optionale Cookies</strong> für Zwecke der Website-Analyse und des Marketings. In den folgenden Tabellen beschreiben wir die auf dieser Website eingesetzten Cookies im Detail:</p>

      <a name="cookies"></a>
    </section>
    <section>
      <a name="ihre-einwilligung-vorausgesetzt"></a>
      <h4>Ihre Einwilligung vorausgesetzt</h4>
      <p class="u-p">Wir verwenden optionale Cookies nur mit Ihrer vorherigen Einwilligung (Art. 6(1)(a) Datenschutz-Grundverordnung). Sofern Sie zum ersten Mal unsere Website besuchen, erscheint auf unserer Website ein Banner, in welchem wir Sie um Ihre Einwilligung zur Verwendung von optionalen Cookies bitten. Erteilen Sie hierzu Ihre Einwilligung, speichern wir ein Cookie auf Ihrem Rechner und das Banner wird für die Lebensdauer des Cookies nicht erneut angezeigt. Danach, oder wenn Sie dieses Cookie zuvor aktiv löschen, wird das Banner beim nächsten Besuch unserer Website wieder eingeblendet, um Ihre Einwilligung erneut einzuholen.</p>
      <h5>Wie Sie die Nutzung von Cookies verhindern</h5>
      <p class="u-p">Selbstverständlich können Sie unsere Website auch ganz ohne Cookies nutzen. Sie können den Einsatz von Cookies in den Einstellungen Ihres Browsers jederzeit konfigurieren oder gänzlich deaktivieren. Dies kann jedoch zu Einschränkungen der Funktionen oder der Benutzerfreundlichkeit unseres Angebots führen. Sie können der Nutzung optionaler Cookies jederzeit widersprechen, indem Sie die entsprechende Widerspruchsmöglichkeit in der <anchor-link to="cookies">Tabelle oben</anchor-link> nutzen.</p>
    </section>
    <section>
      <a name="website-analyse-mit-google"></a>
      <h4>Website-Analyse mit Google</h4>
      <p class="u-p">Auf dieser Website verwenden wir einen Webanalysedienst der Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, United States („Google“).</p>
      <p class="u-p">Google analysiert in unserem Auftrag Ihre Nutzung dieser Website. Hierfür verwenden wir u.a. Cookies, die in der Cookie-Tabelle oben im Detail beschrieben sind. Die dabei von Google erhobenen Informationen über Ihre Nutzung dieser Website (z.B. die verweisende URL, bei uns besuchte Seiten, Ihr verwendeter Webbrowser, Ihre Spracheinstellung, Ihr verwendetes Betriebssystem oder Ihre Bildschirmauflösung) werden an einen Server von Google in die USA übertragen, dort gespeichert, analysiert und das Ergebnis uns in anonymisierter Form zur Verfügung gestellt. Ihre Nutzungsdaten werden dabei nicht mit Ihrer vollen IP-Adresse verbunden. Wir haben auf dieser Website die von Google angebotene IP-Anonymisierungsfunktion aktiviert, sodass das letzte Oktett (Typ IPv4) bzw. die letzten 80 Bits (Typ IPv6) Ihrer IP-Adresse nach jeder Übermittlung an Google gelöscht werden. Google ist unter dem EU-US Privacy Shield zertifiziert, sodass ein angemessenes Datenschutzniveau bei der Verarbeitung personenbezogener Daten durch Google in den USA gewährleistet ist.</p>
      <p class="u-p">Sie können Ihre erteilte Einwilligung zur Webanalyse jederzeit widerrufen, indem Sie entweder das verfügbare Browser-Plugin von Google herunterladen und installieren oder Ihre Einwilligungen in der <anchor-link to="cookies">Tabelle</anchor-link> oben verwalten, wobei ein Opt-Out-Cookie gesetzt wird. Beide Möglichkeiten verhindern eine Webanalyse nur solange, wie Sie einen Browser nutzen, auf dem Sie das Plugin installiert haben bzw. solange Sie das Opt-Out-Cookie nicht löschen.</p>
    </section>
    <section>
      <a name="verhaltensbasierte-werbung-mit-google"></a>
      <h4>Verhaltensbasierte Werbung mit Google</h4>
      <p class="u-p">Diese Website benutzt einen sog. Targeted–Advertising-Dienst der Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, United States („Google“).</p>
      <p class="u-p">Google analysiert in unserem Auftrag Ihre Nutzung dieser Website. Hierfür verwendet Google u.a. Cookies, die in der <anchor-link to="cookies">Cookie-Tabelle</anchor-link> oben im Detail beschrieben sind. Die dabei von Google erhobenen Informationen über Ihre Nutzung dieser Website (z.B. die verweisende URL, bei uns besuchte Seiten, Ihr verwendeter Webbrowser, Ihre Spracheinstellung, Ihr verwendetes Betriebssystem oder Ihre Bildschirmauflösung) werden an einen Server von Google in die USA übertragen, dort gespeichert, analysiert. Wir und unser Partner Google verwenden diese Informationen, um Werbung gezielter auf Sie und Ihre Interessen abzustimmen, einzuschränken, wie oft Sie dieselbe Werbeanzeige zu sehen bekommen, die Wirksamkeit einer Werbekampagne zu messen, oder das Verhalten von Personen nach dem Betrachten einer Anzeige zu verstehen. Wenn Sie eine andere Website im sog. „Google Display-Netzwerk“ besuchen, können Ihnen dort auf Grundlage der erfassten Informationen individualisierte und interessenbezogene Werbeeinblendungen angezeigt werden, die zuvor auf unserer Website aufgerufene Informationen berücksichtigen.</p>
      <p class="u-p">Google ist unter dem EU-US Privacy Shield zertifiziert, sodass ein angemessenes Datenschutzniveau bei der Verarbeitung personenbezogener Daten durch Google in den USA gewährleistet ist.</p>
      <p class="u-p">Sie können Ihre Einwilligung in die Übermittlung von Informationen an Google für Zwecke der verhaltensbasierten Werbung jederzeit widerrufen, indem Sie entweder Ihre Einwilligungen in der <anchor-link to="cookies">Tabelle oben</anchor-link> verwalten, wobei ein Opt-Out-Cookie gesetzt wird, oder indem Sie das von Google angebotene <a href="https://myaccount.google.com/not-supported?hl=de&ref=/settings/ads/plugin" target="_blank">Browser-Plugin</a> herunterladen und installieren. Beide Möglichkeiten verhindern eine Nutzung von Targeted Advertising Diensten nur solange, wie Sie einen Browser nutzen, auf dem Sie das Plugin installiert haben bzw. solange Sie das Opt-Out-Cookie nicht löschen.</p>
      <p class="u-p">Google ist für die Verarbeitung Ihrer personenbezogenen Daten verantwortlich, die Google direkt aus unserer Website für Zwecke der verhaltensbasierten Werbung erhebt. Da wir keine Kontrolle über die von Dritten erhobenen und verarbeiteten personenbezogenen Daten haben, sind wir nicht in der Lage, verbindliche Informationen über Umfang, Zweck oder Dauer einer solchen Verarbeitung Ihrer personenbezogenen Daten zu geben. Bitte besuchen Sie daher die Informationen von Google zum Datenschutz, um weitere Informationen darüber zu erhalten, wie und wie lange Google Ihre personenbezogenen Daten verarbeitet. Zum Zeitpunkt der Erstellung dieser Datenschutzerklärung waren die Informationen von Google in den <a href="www.google.com/privacy/ads/" target="_blank">Google Datenschutzrichtlinien für Werbung</a>.</p>
    </section>
    <section>
      <a name="verhaltensbasierte-werbung-mit-facebook"></a>
      <h4>Verhaltensbasierte Werbung mit Facebook</h4>
      <p class="u-p">Diese Website nutzt einen sogenannten Targeted-Advertising-Dienst von Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland (mit Facebook Inc., 1 Hacker Way, Menlo Park, CA 94025, USA als Subprozessor) („Facebook“).</p>
      <p class="u-p">Facebook wird Ihre Nutzung dieser Website analysieren. Hierfür verwendet Facebook u.a. Cookies, die in der Cookie-Tabelle oben im Detail beschrieben sind. Die von Facebook im Zusammenhang mit Ihrer Nutzung unserer Website erfassten Informationen werden an einen Server von Facebook in den USA übertragen, dort gespeichert und analysiert. Dazu gehören z.B. Ihre Spracheinstellungen, Ihr Betriebssystem, Ihre Bildschirmauflösung und weitere Informationen in Abhängigkeit von den implementierten Website-Ereignissen (z.B. "Kauf": verarbeitet Art, ID und Nummer des ausgewählten Artikels sowie Zahlungsinformationen und Währung; "Suche": verarbeitet den Suchbegriff, wenn Sie in der App nach einem Produkt suchen; "Inhalt ansehen": verarbeitet die Inhalts-ID, Name, Typ, Währung und Wert). Wir und unser Partner Facebook verwenden diese Informationen, um unsere Werbung besser auf Sie und Ihre Interessen abzustimmen, um die Anzahl der Anzeigen zu begrenzen, die Ihnen dieselbe Werbung anzeigen, um die Effizienz von Werbekampagnen zu bewerten und um das Verhalten der Besucher nach dem Betrachten einer bestimmten Anzeige besser zu verstehen. Wenn Sie Ihre Facebook-Timeline oder eine andere Website des Werbenetzwerks von Facebook (sog. „Audience Network“) besuchen, können Ihnen auf Ihre Interessen zugeschnittene Feeds auf der Grundlage der in unserer Website erfassten Informationen präsentiert werden.</p>
      <p class="u-p">Facebook Inc. ist unter dem <a href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active">EU-US Privacy Shield</a> zertifiziert, sodass ein angemessenes Datenschutzniveau bei der Verarbeitung personenbezogener Daten durch Facebook in den USA gewährleistet ist.</p>
      <p class="u-p">Sie können Ihre Einwilligung in die Übermittlung von Informationen an Facebook für Zwecke der verhaltensbasierten Werbung jederzeit widerrufen, indem Sie Ihre Einwilligungen in der <anchor-link to="cookies">Tabelle oben</anchor-link> verwalten, wobei ein Opt-Out-Cookie gesetzt wird. Bitte beachten Sie, dass eine Nutzung von Targeted Advertising Diensten nur solange ausgeschlossen ist, wie Sie das Opt-Out-Cookie nicht löschen.</p>
      <p class="u-p">Facebook ist für die Verarbeitung Ihrer personenbezogenen Daten verantwortlich, die Facebook direkt von unserer Website für Zwecke der verhaltensbasierten Werbung erhebt. Da wir keine Kontrolle über die von Dritten erhobenen und verarbeiteten personenbezogenen Daten haben, sind wir nicht in der Lage, verbindliche Informationen über Umfang, Zweck oder Dauer einer solchen Verarbeitung Ihrer personenbezogenen Daten zu geben. Bitte besuchen Sie daher die Informationen von Facebook zum Datenschutz, um weitere Informationen darüber zu erhalten, wie und wie lange Facebook Ihre personenbezogenen Daten verarbeitet. Zum Zeitpunkt der Erstellung dieser Datenschutzerklärung waren die Informationen von Facebook in den <a href="https://www.facebook.com/about/privacy" target="_blank">Facebook-Datenschutzrichtlinien für Werbung</a> verfügbar.</p>
    </section>
    <section>
      <a name="online-verhaltenswerbung"></a>
      <h4>Online-Verhaltenswerbung</h4>
      <p class="u-p">Diese Website benutzt DoubleClick, ein sog. Targeted Advertising Dienst der Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, United States („Google“).</p>
      <p class="u-p">Google analysiert in unserem Auftrag Ihre Nutzung dieser Website. Hierfür verwenden wir u.a. Cookies, die in der Cookie-Tabelle oben im Detail beschrieben sind. Die dabei von Google erhobenen Informationen über Ihre Nutzung dieser Website (z.B. die verweisende URL, bei uns besuchte Seiten, ihr verwendeter Webbrowser, ihre Spracheinstellung, ihr verwendetes Betriebssystem oder Ihre Bildschirmauflösung) werden an einen Server von Google in die USA übertragen, dort gespeichert, analysiert. Wir und unser Partner Google verwenden diese Informationen, um Werbung gezielter auf Sie und Ihre Interessen abzustimmen, einzuschränken, wie oft Sie dieselbe Werbeanzeige zu sehen bekommen, die Wirksamkeit einer Werbekampagne zu messen, oder das Verhalten von Personen nach dem Betrachten einer Anzeige zu verstehen. Wenn Sie eine andere Website im sog. „Google Display-Netzwerk“ besuchen, können Ihnen dort auf Grundlage der erfassten Informationen individualisierte und interessenbezogene Werbeeinblendungen angezeigt werden, die zuvor auf unserer Website aufgerufene Informationen berücksichtigen.</p>
      <p class="u-p">Sie können Ihre Einwilligung in diese Art der Analyse Ihrer Nutzung dieser Website jederzeit widerrufen, indem Sie entweder Ihre Einwilligungen in der <anchor-link to="cookies">Tabelle oben</anchor-link>, wobei ein Opt-Out-Cookie gesetzt wird, oder indem Sie das von Google angebotene <a href="https://myaccount.google.com/not-supported?hl=de&ref=/settings/ads/plugin" target="_blank">Browser-Plugin</a> herunterladen und installieren. Beide Möglichkeiten verhindern eine Webanalyse nur solange, wie Sie einen Browser nutzen, auf dem Sie das Plugin installiert haben bzw. solange Sie das Opt-Out-Cookie nicht löschen.</p>
      <p class="u-p">Weitere Informationen zu Google DoubleClick erhalten Sie in den <a href="www.google.com/privacy/ads/" target="_blank">Google Datenschutzhinweisen für Werbung</a>.</p>
    </section>
    <section>
      <a name="nutzung-von-google-recaptcha"></a>
      <h4>Google reCAPTCHA</h4>
      <p class="u-p">In unserem Internetauftritt setzen wir Google reCAPTCHA zur Überprüfung und Vermeidung von Interaktionen auf unserer Internetseite durch automatisierte Zugriffe, bspw. durch sog. Bots, ein. Es handelt sich hierbei um einen Dienst der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, nachfolgend nur „Google“ genannt.</p>
      <p class="u-p">Durch die Zertifizierung nach dem <a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active" target="_blank">EU-US-Datenschutzschild („EU-US Privacy Shield“)</a> garantiert Google, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.</p>
      <p class="u-p">Durch diesen Dienst kann Google ermitteln, von welcher Webseite eine Anfrage gesendet wird sowie von welcher IP-Adresse aus Sie die sog. reCAPTCHA-Eingabebox verwenden. Neben Ihrer IP-Adresse werden womöglich noch weitere Informationen durch Google erfasst, die für das Angebot und die Gewährleistung dieses Dienstes notwendig sind.</p>
      <p class="u-p">Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Sicherheit unseres Internetauftritts sowie in der Abwehr unerwünschter, automatisierter Zugriffe in Form von Spam o.ä..</p>
      <p class="u-p">Google bietet unter <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a> weitergehende Informationen zu dem allgemeinen Umgang mit Ihren Nutzerdaten an.</p>
    </section>
    <section>
      <a name="nutzung-von-spotify"></a>
      <h4>Verwendung von Widgets von Spotify</h4>
      <p class="u-p">Auf unserer Website werden zum Zweck einer interaktiven Gestaltung unserer Inhalte sogenannte Widgets von den Netzwerken Spotify verwendet. Dies dient der Wahrung unserer überwiegenden berechtigten Interessen an einer multimedialen Darstellung unseres Angebots und unserer Aktivtäten gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO.</p>
      <p class="u-p">Wenn Sie eine Seite unseres Webauftritts aufrufen, die ein solches Widget enthält, stellt Ihr Browser eine direkte Verbindung zu den Servern von Spotify her. Der Inhalt des Widgets wird vom Anbieter direkt an Ihren Browser übermittelt und in die Seite eingebunden. Durch die Einbindung der Widgets erhalten die Anbieter die Information, dass Ihr Browser die entsprechende Seite unseres Webauftritts aufgerufen hat, auch wenn Sie kein Profil besitzen oder gerade nicht eingeloggt sind. Diese Information (einschließlich Ihrer IP-Adresse) wird von Ihrem Browser direkt an einen Server des jeweiligen Anbieters übermittelt und dort gespeichert.</p>
      <p class="u-p">Sind Sie bei einem der Dienste eingeloggt, können die Anbieter den Besuch unserer Webseite Ihrem Profil in dem jeweiligen sozialen Netzwerk unmittelbar zuordnen. Wenn Sie mit den Widgets interagieren, zum Beispiel Inhalte abspielen, wird die entsprechende Information ebenfalls direkt an einen Server der Anbieter übermittelt und dort gespeichert.</p>
      <p class="u-p">Die Informationen können außerdem in dem sozialen Netzwerk veröffentlicht und dort Ihren Kontakten angezeigt werden.</p>
      <p class="u-p">Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch die Anbieter sowie eine Kontaktmöglichkeit und Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte den Datenschutzhinweisen der Anbieter.</p>
      <p class="u-p">Spotify AB: https://www.spotify.com/de/legal/privacy-policy/</p>
      <p class="u-p">Wenn Sie nicht möchten, dass die sozialen Netzwerke die über unseren Webauftritt gesammelten Daten unmittelbar Ihrem Profil in dem jeweiligen Dienst zuordnen, können Sie das Laden der Widgets auch mit Add-Ons für Ihren Browser komplett verhindern, z. B. mit dem Skript-Blocker „NoScript“ (http://noscript.net/).</p>
    </section>
    <section>
      <a name="nutzung-facebook-pixel"></a>
      <h4>Konversionsmessung mit dem Conversion-Pixel von Facebook</h4>
      <p class="u-p">Wir setzen den “Conversion-Pixel“ bzw. Besucheraktions-Pixel der Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA (“Facebook”) ein. Durch den Aufruf dieses Pixels aus Ihrem Browser kann Facebook in der Folge erkennen, ob eine Facebook-Werbeanzeige erfolgreich war, also z.B. zu einem online-Kaufabschluss geführt hat. Wir erhalten von Facebook hierzu ausschließlich statistische Daten ohne Bezug zu einer konkreten Person. So können wir die Wirksamkeit der Facebook-Werbeanzeigen für statistische und Marktforschungszwecke erfassen. Insbesondere falls Sie bei Facebook angemeldet sind, verweisen wir im Übrigen auf deren Datenschutzinformationen <a href="https://www.facebook.com/ads/preferences/" target="_blank">https://www.facebook.com/ads/preferences/</a>.
      <p class="u-p">Bitte gehen Sie auf <a href="https://www.facebook.com/ads/preferences/" target="_blank">https://www.facebook.com/ads/preferences/</a>, wenn Sie Ihre Einwilligung zu Conversion Pixel widerrufen möchten.</p>
    </section>
    <section>
      <a name="nutzung-von-kontaktformularen"></a>
      <h4>Nutzung von Kontaktformularen</h4>
      <p class="u-p">Über auf der Website bereitgestellte Kontaktformulare können Sie direkt mit uns in Verbindung treten. Sie haben dabei die Möglichkeit, uns insbesondere folgende Informationen mitzuteilen:</p>
      <ul>
        <li>Vorname</li>
        <li>Nachname</li>
        <li>E-Mail</li>
      </ul>
      <p class="u-p">Wir erheben, verarbeiten und nutzen die von Ihnen über Kontaktformulare mitgeteilten Informationen ausschließlich für die Bearbeitung Ihres konkreten Anliegens.</p>
    </section>
    <section>
      <a name="externe-dienste-und-inhalte-auf-unserer-website"></a>
      <h4>Externe Dienste und Inhalte auf unserer Website</h4>
      <p class="u-p">Wir binden externe Dienste oder Inhalte auf unserer Website ein. Wenn Sie einen solchen Dienst verwenden oder wenn Ihnen Inhalte Dritter angezeigt werden, werden technisch bedingt Kommunikationsdaten zwischen Ihnen und dem jeweiligen Anbieter ausgetauscht.</p>
      <p class="u-p">Darüber hinaus kann es sein, dass der Anbieter der jeweiligen Dienste oder Inhalte Ihre Daten für weitere, eigene Zwecke verarbeitet. Dienste oder Inhalte von Anbietern, die bekanntermaßen Daten für eigene Zwecke verarbeiten, haben wir nach bestem Wissen und Gewissen so konfiguriert, dass entweder eine Kommunikation für andere Zwecke als zur Darstellung der Inhalte bzw. Dienste auf unserer Website unterbleibt, oder eine Kommunikation erst dann stattfindet, wenn Sie sich aktiv dazu entscheiden, den Dienst zu verwenden. Da wir aber keinen Einfluss auf die von Dritten erhobenen Daten und deren Verarbeitung durch diese haben, können wir zu Zweck und Umfang der Verarbeitung Ihrer Daten keine verbindlichen Angaben machen.</p>
      <p class="u-p">Weitere Informationen über Zweck und Umfang der Erhebung und Verarbeitung Ihrer Daten entnehmen Sie daher bitte den Datenschutzhinweisen der jeweils datenschutzrechtlich verantwortlichen Anbieter der von uns eingebundenen Dienste bzw. Inhalte:</p>
      <ul>
        <li>Facebook Teilen-Button</li>
        <li>Google Maps Kartenmaterial</li>
        <li>Twitter Teilen-Button </li>
      </ul>
    </section>
    <section>
      <a name="hinweise-zu-nebenwirkungen-oder-qualitätsbeanstandungen"></a>
      <h4>Hinweise zu Nebenwirkungen oder Qualitätsbeanstandungen</h4>
      <p class="u-p">Diese Website ist nicht dafür vorgesehen, unerwünschte Nebenwirkungen, mangelnde Arzneimittelwirksamkeit, Medikationsfehler, Graumarktware/Fälschungen, falsche oder zulassungsüberschreitende (off-label) Anwendung, eine Qualitätsbeanstandung und/oder andere Aspekte im Zusammenhang mit der Sicherheit oder Qualität eines Bayer-Produkts mitzuteilen. Wenn Sie Nebenwirkungen oder Qualitätsbeanstandungen melden wollen, wenden Sie sich bitte an Ihren Gesundheitsdienstleister (z.B. Arzt oder Apotheker), die örtliche Gesundheitsbehörde oder nutzen Sie unsere <a href="pharma.bayer.com/de/therapie-versorgung/eine-nebenwirkung-melden/" target="_blank">Website zur Meldung unerwünschter Nebenwirkungen</a>.</p>
      <p class="u-p">Sollten Sie uns dennoch unerwünschte Nebenwirkungen oder andere Aspekte im Zusammenhang mit der Sicherheit oder Qualität von Arzneimitteln oder Medizinprodukten mitteilen, sind wir gesetzlich dazu verpflichtet Ihre Mitteilung zu bearbeiten. Zu diesem Zweck kann es auch sein, dass wir Sie zur Klärung von Fragen kontaktieren. Die von Ihnen gemachten Mitteilungen müssen wir anschließend möglicherweise an die zuständigen Gesundheitsbehörden melden, wobei wir Ihre Informationen ausschließlich in pseudonymer Form weitergeben, sodass keine Sie direkt identifizierenden Informationen weitergegeben werden. Diese pseudonymen Meldungen müssen wir gegebenenfalls auch an unsere weiteren Konzerngesellschaften und Kooperationspartner weitergeben, sofern diese ihrerseits verpflichtet sind Meldungen an die für sie zuständigen Gesundheitsbehörden zu machen.</p>
    </section>
    <section>
      <a name="weitergabe-von-daten-zur-verarbeitung-in-unserem-auftrag"></a>
      <h3>Weitergabe von Daten zur Verarbeitung in unserem Auftrag</h3>
      <p class="u-p">Zur Verarbeitung Ihrer Daten setzen wir zum Teil spezialisierte Dienstleister ein. Unsere Dienstleister werden von uns sorgfältig ausgewählt und regelmäßig kontrolliert. Sie verarbeiten personenbezogene Daten nur in unserem Auftrag und strikt nach unseren Weisungen auf der Grundlage entsprechender Verträge über eine Auftragsverarbeitung.</p>
    </section>
    <section>
      <a name="verarbeitung-von-daten-außerhalb-der-eu-des-ewr"></a>
      <h3>Verarbeitung von Daten außerhalb der EU / des EWR</h3>
      <p class="u-p">Ihre Daten werden zum Teil auch in Ländern außerhalb der Europäischen Union („EU“) oder des Europäischen Wirtschaftsraums („EWR“) verarbeitet, wo generell ein geringeres Datenschutzniveau herrschen könnte als in Europa. In diesen Fällen stellen wir z.B. über vertragliche Vereinbarungen mit unseren Vertragspartnern sicher, dass ein ausreichendes Datenschutzniveau für Ihre Daten gewährleistet ist (Kopie auf Anfrage erhältlich), oder wir bitten Sie um Ihre ausdrückliche Einwilligung.</p>
    </section>
    <section>
      <a name="emotionserkennung-gewinnspiel"></a>
      <h3>Emotionserkennung & Gewinnspiel</h3>
      <h4>Verarbeitungszweck(e) und Kategorien personenbezogener Daten</h4>
      <p class="u-p">Wir verarbeiten die folgenden personenbezogenen Daten für folgende Zwecke:</p>
      <h5>Emotionserkennung</h5>
      <p class="u-p">Wenn Sie Ihr Portrait über Kamera oder Foto hochladen, um eine persönliche Playlist zu erhalten, ermittelt die Analysesoftware Ihren Gemütszustand. Ihr Foto wird anschließend unmittelbar gelöscht.</p>
      <h5>Teilnahme an Gewinnspiel</h5>
      <p class="u-p">Wir verarbeiten Ihren Namen und Vornamen, sowie Ihre E-Mail-Adresse zur Gewinnauslosung und zur Zusendung des Gutscheins an Ihre E-Mail-Adresse inkl. persönlicher E-Mail-Ansprache im Falle des Gewinns.</p>
    </section>
    <section>
      <h4>Auftragsverarbeitung</h4>
      <p class="u-p">Für die Verarbeitung Ihrer personenbezogenen Daten setzen wir in gewissem Umfang spezialisierte Dienstleister ein, die Ihre Daten in unserem Auftrag verarbeiten. Unsere Dienstleister werden von uns sorgfältig ausgewählt und regelmäßig kontrolliert. Sie verarbeiten personenbezogene Daten nur in unserem Auftrag und nach unseren Weisungen auf der Grundlage entsprechender Verträge über eine Auftragsverarbeitung.</p>
    </section>
    <section>
      <h4>Aufbewahrungsfristen für personenbezogene Daten</h4>
      <p class="u-p">Ihre personenbezogenen Daten werden nach der Emotionserkennung bzw. nach Gewinnauslosung gelöscht.</p>
    </section>
    <section>
      <h2 class="u-h3">Rechtsgrundlagen für eine Verarbeitung Ihrer personenbezogenen Daten</h2>
      <table>
        <tbody>
          <tr>
            <th>Zweck</th>
            <td>Emotions&shy;erkennung</td>
            <td>Gewinn&shy;auslosung</td>
          </tr>
          <tr>
            <th>Rechts&shy;grundlage</th>
            <td>Art. 6 (1) a) DSGVO</td>
            <td>Art. 6 (1) a) DSGVO</td>
          </tr>
          <tr>
            <th>Erläuterung</th>
            <td>Die betroffene Person hat ihre Einwilligung zur Verarbeitung gegeben</td>
            <td>Die betroffene Person hat ihre Einwilligung zur Verarbeitung gegeben</td>
          </tr>
          <tr>
            <th>Bereitstellung erforderlich</th>
            <td>Ja</td>
            <td>Ja</td>
          </tr>
          <tr>
            <th>Folgen einer Nichtbereit&shy;stellung</th>
            <td>Keine Emotions&shy;erkennung und Erhalt einer Playlist möglich</td>
            <td>Keine Gewinnspiel&shy;teilnahme möglich</td>
          </tr>
        </tbody>
      </table>
    </section>
    <section>
      <a name="informationen-über-ihre-rechte"></a>
      <h2 class="u-h1">Informationen über Ihre Rechte</h2>
      <p class="u-p">Die folgenden Rechte stehen Ihnen aufgrund der anwendbaren Datenschutzgesetze zur Verfügung:</p>
      <ul>
        <li>Recht auf Auskunft über die bei uns zu Ihrer Person gespeicherten Daten;</li>
        <li>Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung Ihrer personenbezogenen Daten;</li>
        <li>Recht auf Widerspruch gegen eine Verarbeitung, die unserem berechtigten Interesse, einem öffentlichen Interesse oder einem Profiling dient, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen;</li>
        <li>Recht auf Datenübertragbarkeit;</li>
        <li>Recht sich bei einer Aufsichtsbehörde zu beschweren;</li>
        <li>Von Ihnen erteilte Einwilligungen zur Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten können Sie jederzeit mit Wirkung für die Zukunft widerrufen. Nähere Informationen hierzu finden Sie in den jeweiligen Abschnitten oben, wo eine Datenverarbeitung auf Grundlage Ihrer Einwilligung beschrieben wird.</li>
      </ul>
      <p class="u-p">Falls Sie von Ihren Rechten Gebrauch machen möchten, richten Sie bitte Ihr Anliegen an den unten genannten <anchor-link to="kontakt">Ansprechpartner</anchor-link>.</p>
    </section>
    <section>
      <a name="kontakt"></a>
      <h2 class="u-h1">Kontakt</h2>
      <p class="u-p">Sollten Sie Fragen in Zusammenhang mit dem Datenschutz haben oder von Ihren Rechten Gebrauch machen wollen, wenden Sie sich bitte an:</p>
      <p class="u-p">Bayer Vital GmbH<br>Datenschutz<br>Kaiser-Wilhelm-Allee 70<br>51366 Leverkusen<br>Fax.: +49 (0) 214 30 51687<br>E-Mail: <a href="mailto:datenschutz-bv@bayer.com">datenschutz-bv@bayer.com</a></p>
    </section>
    <section>
      <a name="anpassung-der-datenschutzerklärung"></a>
      <h2 class="u-h1">Anpassung der Datenschutzerklärung</h2>
      <p class="u-p">Wir behalten uns vor, diese Datenschutzerklärung von Zeit zu Zeit zu aktualisieren. Aktualisierungen dieser Datenschutzerklärung werden auf unserer Website veröffentlicht. Änderungen gelten ab ihrer Publikation auf unserer Website. Wir empfehlen Ihnen daher, diese Seite regelmäßig zu besuchen, um sich über gegebenenfalls erfolgte Aktualisierungen zu informieren.</p>
      <p class="u-p">Stand: 30.08.2019</p>
    </section>
  </article>
</template>

<script>
import AnchorLink from '@/components/AnchorLink'

export default {
  components: {
    AnchorLink
  },
  data() {
    return {
      cookieTableHeight: 0
    }
  },
  methods: {
    resizeCookieTable(event) {
      if (event.data.height) {
        this.cookieTableHeight = event.data.height
      }
    }
  },
  mounted() {
    if (!this.$messageListener) {
      this.$messageListener = window.addEventListener(
        'message',
        this.resizeCookieTable
      )
    }
    if (window.location.hash) {
      const $target = document.querySelector(window.location.hash)
      if ($target) {
        window.scrollTo({ top: this.$target.offsetTop - 200 })
      }
    }
  }
}
</script>

<style>
.b-cookie-iframe {
  overflow: hidden;
  width: 100%;
  border: none;
  color: white;
  font-family: Proxima-N-W01-At-Reg;
  transition: height 0.3s ease;
}
</style>
